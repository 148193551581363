import './scss/styles.scss';
import $ from 'jquery';

$(() => {
  $('.header-menu-button').on('click', () => {
    $('.small-screen-menu').addClass('small-screen-menu--open');
    $('html').css('overflow', 'hidden');
  });

  $('.small-screen-menu-close').on('click', () => {
    $('.small-screen-menu').removeClass('small-screen-menu--open');
    $('html').css('overflow', 'auto');
  });
});
